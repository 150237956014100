import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/data-dashboard/app/[locale]/HydrationCheck.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/data-dashboard/app/[locale]/NextIntlProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/data-dashboard/app/[locale]/RootStoreInitializer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/data-dashboard/components/Toaster/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.20_@babel+core@7.26.7_@opentelemetry+api@1.9.0_@playwright+test@1.50.1_react-dom@18_nj2u5fwuic6phqql7bw45ijx24/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.20_@babel+core@7.26.7_@opentelemetry+api@1.9.0_@playwright+test@1.50.1_react-dom@18_nj2u5fwuic6phqql7bw45ijx24/node_modules/next/font/local/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-sans\",\"src\":[{\"path\":\"../../assets/fonts/MBCorpoSText-Regular-Web.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../assets/fonts/MBCorpoSText-Bold-Web.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"display\":\"optional\"}],\"variableName\":\"mbFont\"}");
;
import(/* webpackMode: "eager" */ "/app/apps/data-dashboard/styles/globals.css");
