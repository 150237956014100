export type availableLocalesType = readonly ['en', 'de']

export const availableLocales: availableLocalesType = ['en', 'de']
export const defaultLocale = availableLocales[0]
export const localePrefix = 'always'

export type Locale = (typeof availableLocales)[number]

type LocaleMap = {
  [K in Locale]: string
}

type LanguageNames = LocaleMap
export const languageNames: LanguageNames = {
  de: 'Deutsch',
  en: 'English'
}

export const defaultDateLocale = 'en-GB'
export const defaultDateTimezone = 'Etc/GMT'
